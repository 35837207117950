import { getFormProps, getInputProps, useForm } from '@conform-to/react';
import { conformZodMessage, parseWithZod } from '@conform-to/zod';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
  json,
} from '@remix-run/node';
import { Form, useActionData, useLoaderData } from '@remix-run/react';
import * as z from 'zod';

import { User } from '@prisma/client';
import logo from '~/assets/logo.png';
import ddma from '~/assets/logo.svg';
import { authenticator } from '~/auth';
import { commitSession, getSession } from '~/auth/session';
import { Button } from '~/components/ui/button';
import { Input } from '~/components/ui/input';
import { Label } from '~/components/ui/label';
import { findOrCreateUser } from '../members/queries';

export const meta: MetaFunction = () => {
  return [{ title: 'Login | DDMA' }];
};

function createSchema(options?: {
  isExistingUser: (email: string) => Promise<User | null>;
}) {
  return z.object({
    email: z
      .string({ required_error: 'Verplicht' })
      .email('Ongeldig e-mailadres')
      .pipe(
        z.string().superRefine((email, ctx) => {
          // This makes Conform to fallback to server validation
          // by indicating that the validation is not defined
          if (typeof options?.isExistingUser !== 'function') {
            ctx.addIssue({
              code: 'custom',
              message: conformZodMessage.VALIDATION_UNDEFINED,
              fatal: true,
            });
            return;
          }

          return options.isExistingUser(email).then((exists) => {
            if (!exists) {
              ctx.addIssue({
                code: 'custom',
                message: 'User not found',
              });
            }
          });
        })
      ),
  });
}

export async function loader({ request }: LoaderFunctionArgs) {
  await authenticator.isAuthenticated(request, {
    successRedirect: '/',
  });

  const cookie = await getSession(request.headers.get('cookie'));
  const authEmail = cookie.get('auth:email');
  const authError = cookie.get(authenticator.sessionErrorKey);

  // Commit session to clear any `flash` error message.
  return json({ authEmail, authError } as const, {
    headers: {
      'set-cookie': await commitSession(cookie),
    },
  });
}

export async function action({ request }: ActionFunctionArgs) {
  const url = new URL(request.url);
  const currentPath = url.pathname;

  const formData = await request.clone().formData(); // request is cloned so it can be read by remix-auth later
  const submission = await parseWithZod(formData, {
    schema: createSchema({
      isExistingUser: findOrCreateUser,
    }),
    async: true,
  });

  if (submission.status !== 'success') {
    return json(submission.reply());
  }

  return await authenticator.authenticate('TOTP', request, {
    successRedirect: '/auth/verify',
    failureRedirect: currentPath,
  });
}

export default function Login() {
  const { authEmail, authError } = useLoaderData<typeof loader>();
  let lastResult = useActionData<typeof action>();

  let [form, fields] = useForm({
    lastResult,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: createSchema() });
    },
    shouldValidate: 'onBlur',
  });

  return (
    <div className="h-dvh w-full flex flex-col relative ">
      <Form
        method="POST"
        autoComplete="off"
        {...getFormProps(form)}
        className="flex flex-col gap-6 p-10 w-[496px] rounded-sm border bg-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="flex justify-center p-6">
          <img src={logo} alt="logo DDMA" className="h-20 w-auto" />
        </div>
        <div>
          <h1 className="text-center pb-2">Inloggen bij DDMA</h1>
        </div>
        {/* Errors Handling. */}
        {!authEmail && authError && (
          <p className="font-semibold text-red-400">{authError.message}</p>
        )}

        <div>
          <Label htmlFor="email">E-mail</Label>
          <Input
            {...getInputProps(fields.email, { type: 'email' })}
            placeholder="E-mail"
          />
          {/* Conform Errors Handling. */}
          {fields.email.errors && (
            <p className="text-red-400">{fields.email.errors.join(' ')}</p>
          )}
        </div>
        <Button variant="default" type="submit" className="w-full">
          Stuur login e-mail
        </Button>
        <div className="absolute top-full left-1/2 -translate-x-1/2 flex justify-center p-6 mt-20">
          <img src={ddma} alt="logo DDMA" className="h-12 w-auto" />
        </div>
      </Form>
    </div>
  );
}
